

















































import Deal from '@/models/Deal.model';
import { namespace } from 'vuex-class';
import { Component, Prop } from 'vue-property-decorator';
import { DealStoreMutations } from '@/store/deal.store';
import { ROUTE_DEAL } from '@/router/routes';
import { mixins } from 'vue-class-component';
import BaseMixin from '@/misc/BaseMixin.mixins';
import { DisplayType } from '@/enum/DisplayType';

const DealStore = namespace('deals');

@Component
export default class DealListComponent extends mixins(BaseMixin) {
  @Prop({ required: true })
  public deals!: Deal[];

  @Prop({ default: false })
  private isLoading!: boolean;

  @Prop({ default: '' })
  private search!: string;

  @Prop({ default: false })
  public showExpiryReason!: boolean;
  
  @DealStore.Mutation(DealStoreMutations.SET_SELECTED_DEAL)
  private setSelectedDeal!: (deal: Deal) => void;

  private headers = [
    { text: this.$t('DEAL.TITLE'), align: 'start', sortable: true, value: 'title' },
    { text: this.$t('DEAL.OLD_PRICE'), align: 'start', sortable: true, value: 'oldPrice' },
    { text: this.$t('DEAL.LIST.PRICE_HEADER'), align: 'start', sortable: true, value: 'price' },
    { text: this.$t('DEAL.START_DATE'), align: 'start', sortable: true, value: 'startDate' },
    { text: this.$t('DEAL.END_DATE'), align: 'start', sortable: true, value: 'endDate' },
    { text: this.$t('DEAL.CATEGORY'), align: 'start', sortable: true, value: 'category' },
    { text: this.$t('DEAL.CREATOR'), align: 'start', sortable: true, value: 'creator' },
    { text: this.$t('DEAL.EXPIRY_REASON'), align: 'start', sortable: true, value: 'expiryReason' },
  ];

  private DisplayType = DisplayType;

  get filteredHeaders() {
    return this.headers.filter(header => this.showExpiryReason || header.value != 'expiryReason');
  }

  private openDetail(deal: Deal) {
    this.setSelectedDeal(deal);
    this.$router.push({ name: ROUTE_DEAL, params: { dealId: deal.id } });
  }
}
